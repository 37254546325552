<template>
  <div class="home col-12 col-lg-6 c">
    <div class="header">
      <h1>الدعم الفني</h1>
      <h4 class="text-white">
        يمكنك التواصل معنا لأي مشكلة او استفسار او اقتراح.
      </h4>
    </div>
    <!-----start-main---->
    <!---728x90--->

    <div class="login-form">
      <div class="col-12 col-lg-8 c">
        <br />
        <div class="form-group" style="text-align: right !important">
          <label for="">الإسم</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="name"
            placeholder="الإسم هنا..."
          />
        </div>
        <br />
        <div class="form-group" style="text-align: right !important">
          <label for="">رقم الهاتف</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="phone"
            placeholder="الهاتف هنا..."
          />
        </div>
        <br />
        <div class="form-group" style="text-align: right !important">
          <label for="">التواصل معنا بخصوص:</label>
          <br />
          <br />
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="radio"
                name="answer"
                class="form-check-input"
                v-model="service"
                value="brmja"
              />
            </label>
            <label class="form-check-label2">
              <img
                :src="require('@/assets/logo.png')"
                style="width: 30px; height: 30px"
                alt=""
              />
              برمجة دوت كوم (الشركة نفسها)
            </label>
          </div>
          <br />
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                v-model="service"
                value="tahdir"
              />
            </label>
            <label class="form-check-label2">
              <img
                :src="require('@/assets/tahdir.png')"
                style="width: 30px; height: 30px"
                alt=""
              />
              التحضير الذكي</label
            >
          </div>
          <br />
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                v-model="service"
                value="naqar"
              />
            </label>
            <label class="form-check-label2">
              <img
                :src="require('@/assets/naqar.png')"
                style="width: 30px; height: 30px"
                alt=""
              />
              نقار - المساعد الذكي</label
            >
          </div>
          <br />
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                v-model="service"
                value="whatsapp"
              />
            </label>
            <label class="form-check-label2">
              <img
                :src="require('@/assets/whatsapp.png')"
                style="width: 30px; height: 30px"
                alt=""
              />
              الواتس الذكي</label
            >
          </div>
        </div>
        <br />
        <div id="alert"></div>
        <button class="btn btn-lg btn-success btn-block" @click="check()">
          المواصلة للدعم الفني
          <i class="fa fa-arrow-left"></i>
        </button>
        <br />
        <br />
      </div>
    </div>
    <!---728x90--->
    <!--//End-login-form-->
    <!-----start-copyright---->
    <div class="copy-right">
      <!---728x90--->
      <p>جميع الحقوق محفوظة لدى شركة برمجة دوت كوم</p>
    </div>
    <!-----//end-copyright---->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      api: window.api,
      service: "brmja",
    };
  },
  methods: {
    check() {
      $("#alert").html("");
      var phone = $("#phone").val(),
        name = $("#name").val(),
        service = this.service;
      if (!phone || !name) {
        $("#alert").html(
          "<div class='alert alert-danger text-center g'>برجاء كتابة الإسم ورقم الهاتف الخاص بك</div>"
        );
      } else {
        if (phone.trim() != "" && name.trim() != "") {
          localStorage.setItem(
            "last",
            `/?name=${encodeURI(name)}&phone=${encodeURI(
              phone
            )}&service=${service}&id=0`
          );
          window.location = `/?name=${encodeURI(name)}&phone=${encodeURI(
            phone
          )}&service=${service}&id=0`;
        } else {
          $("#alert").html(
            "<div class='alert alert-danger text-center g'>برجاء كتابة الإسم ورقم الهاتف الخاص بك</div>"
          );
        }
      }
    },
  },
  created() {
    setTimeout(() => {
      $(".form-check-label2").click(function () {
        $(this).parent().find("input").click();
      });
    }, 1000);
  },
};
</script>

<style >
.form-check-label2 {
  position: relative;
  right: 35px;
  top: -5px;
  cursor: pointer;
}
</style>